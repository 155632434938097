<template>
  <div>
    <v-card-text>
      <template v-if="!complete">
        <v-alert v-if="!ticketGroups.length" class="mb-0" type="warning" text>
          Você não possui permissão para emitir nenhum lote como cortesia.
        </v-alert>
        <template v-else>
          <v-autocomplete
            v-model="ticketBlock"
            label="Lote"
            outlined
            :items="ticketBlocks"
            :messages="ticketBlockMessage"
          />
          <v-textarea
            v-model="phones"
            outlined
            label="WhatsApp (um por linha)"
            :messages="[
              (numberPhones.length > selectedTicketBlock?.tickets?.remaining
                ? 'Você não possui ingressos suficientes. '
                : '') +
                `${numberPhones.length} telefone${
                  numberPhones.length != 1 ? 's' : ''
                }${selectedTicketBlock ? ` de ${ticketBlockMessage}` : ''}`,
            ]"
            :rules="[
              (v) => !!v || 'Informe pelo menos um telefone',
              (v) =>
                numberPhones.length > 0 ||
                'Informe pelo menos um telefone válido',
              () =>
                selectedTicketBlock
                  ? numberPhones.length <=
                      selectedTicketBlock.tickets.remaining ||
                    'Você não possui ingressos suficientes'
                  : true,
            ]"
          />
          <v-switch
            class="mt-0"
            v-model="removeDuplicates"
            label="Remover duplicados"
            color="primary"
            hide-details
          />
          <v-alert v-if="error" class="mb-0 mt-4" type="error" text>
            {{ error }}
          </v-alert>
        </template>
      </template>
      <template v-else>
        <h5 class="mb-2">
          {{ loading ? "Enviando cortesias" : "Cortesias enviadas" }}
        </h5>
        <v-list>
          <v-list-item
            v-for="(result, i) in results ? results : numberPhones"
            :key="i"
          >
            <v-list-item-content>
              <v-list-item-title class="d-flex align-center gap-2">
                <v-progress-circular size="15" indeterminate v-if="loading" />
                <v-icon v-else-if="result.error" color="error">
                  mdi-alert-circle
                </v-icon>
                <v-icon v-else color="success"> mdi-check-circle </v-icon>

                {{ result.phone || result }}
              </v-list-item-title>
              <v-list-item-subtitle v-if="!loading">
                {{ result.error || "Enviado com sucesso" }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-btn block text :disabled="loading" @click="$emit('close', true)">
          Fechar
        </v-btn>
      </template>
    </v-card-text>
    <v-card-actions v-if="!complete" class="justify-space-between">
      <v-btn text @click="$emit('prev')"> Voltar </v-btn>
      <v-btn
        color="primary"
        @click="send"
        :disabled="
          !ticketBlock ||
          !numberPhones.length ||
          numberPhones.length > selectedTicketBlock.tickets.remaining
        "
      >
        Continuar
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import TICKET from "@/services/admin/ticket";

export default {
  props: {
    party: {
      type: Object,
      required: true,
    },
    ticketGroups: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      ticketBlock: null,
      phones: "",
      removeDuplicates: false,
      complete: false,
      loading: false,
      error: null,
      results: null,
      search: "", // Added search property
    };
  },
  methods: {
    phoneIsValid(value) {
      return value.match(/[(][0-9]{2}[)] [0-9]{5}-[0-9]{4}/);
    },
    phoneMask(value) {
      const stringValue = value.toString();
      return stringValue
        .replace(/\D/g, "")
        .replace(/(\d{2})(\d)/, "($1) $2")
        .replace(/(\d{5})(\d{4})/, "$1-$2")
        .substring(0, 15);
    },
    formatPhones(value) {
      var phones = value.split("\n").map((phone) => phone.trim());
      phones = phones.map((phone) => this.phoneMask(phone));
      if (this.removeDuplicates) phones = [...new Set(phones)];
      this.phones = phones.join("\n");
    },
    async send() {
      try {
        this.loading = true;
        this.error = null;
        this.complete = true;
        this.$emit("next");

        const response = await TICKET.sellLot(
          this.party.organizationId,
          this.party.id,
          {
            type: "courtesy",
            ticketBlock: this.ticketBlock,
            users: this.numberPhones.map((phone) => ({ phone })),
          }
        );
        this.results = response.users;
      } catch (error) {
        this.error = error.message || "Erro ao emitir cortesias";
        this.complete = false;
        this.$emit("prev");
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    removeDuplicates: {
      handler: function (value) {
        if (value) this.formatPhones(this.phones);
      },
      immediate: true,
    },
    phones: {
      handler: function (value) {
        this.formatPhones(value);
      },
      immediate: true,
    },
  },
  computed: {
    ticketBlocks() {
      const ticketBlocks = [];
      this.ticketGroups
        .filter(({ tableGroupId }) => !tableGroupId)
        .forEach((ticketGroup) => {
          ticketGroup.TicketBlock.forEach((ticketBlock) => {
            ticketBlocks.push({
              text: `${ticketGroup.name} - ${ticketBlock.name}`,
              value: ticketBlock.id,
            });
          });
        });
      return ticketBlocks;
    },
    selectedTicketBlock() {
      const tbId = this.ticketBlock;
      if (!tbId) return null;

      var ticketBlock = null;
      for (const ticketGroup of this.ticketGroups) {
        if (ticketBlock) break;
        for (const tb of ticketGroup.TicketBlock) {
          if (tb.id == tbId) {
            ticketBlock = tb;
            break;
          }
        }
      }
      return ticketBlock;
    },
    ticketBlockMessage() {
      if (!this.selectedTicketBlock) return null;
      const { remaining } = this.selectedTicketBlock.tickets;
      return `${remaining} ingresso${remaining != 1 ? "s" : ""} disponíve${
        remaining != 1 ? "is" : "l"
      }`;
    },
    numberPhones() {
      return this.phones.split("\n").filter((a) => this.phoneIsValid(a));
    },
  },
};
</script>

<style></style>
