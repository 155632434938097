<template>
  <div>
    <div class="d-flex justify-space-between align-center mt-2">
      <h6 class="mb-0">FAQ</h6>
      <div class="d-flex gap-2">
        <v-btn
          @click="newSmartFAQ"
          color="primary"
          :small="$vuetify.breakpoint.mdAndDown"
          text
        >
          <v-icon :left="$vuetify.breakpoint.smAndUp">mdi-creation</v-icon>
          <template v-if="$vuetify.breakpoint.smAndUp"> Smart FAQ</template>
        </v-btn>
        <v-btn
          @click="newFAQ"
          color="primary"
          :small="$vuetify.breakpoint.mdAndDown"
        >
          <v-icon :left="$vuetify.breakpoint.smAndUp">mdi-plus</v-icon>
          <template v-if="$vuetify.breakpoint.smAndUp">FAQ</template>
        </v-btn>
      </div>
    </div>
    <div></div>
    <faq-viewer
      v-if="faqs.length > 0"
      :data="faqs"
      @edit-faq="openEditModal"
      @delete-faq="deleteFAQ"
    />
    <v-alert v-else color="info" text class="mt-2 mb-0">
      <p class="text-18 font-weight-bold lh-1">
        Você ainda não tem FAQs cadastradas.
      </p>
      <p class="text-16 font-weight-bold mb-0">O que são FAQs?</p>
      <p>
        FAQs (Perguntas Frequentes) são uma maneira eficaz de fornecer respostas
        rápidas para perguntas comuns que seus clientes possam ter. Elas ajudam
        a melhorar a experiência do cliente, economizam tempo da equipe de
        suporte e podem aumentar a satisfação do cliente.
      </p>
      <p class="text-16 font-weight-bold mb-0">Benefícios das FAQs:</p>
      <ul>
        <li>Reduzem a carga de trabalho da equipe de suporte.</li>
        <li>Melhoram a satisfação e a experiência do cliente.</li>
        <li>Fornecem respostas imediatas a perguntas comuns.</li>
        <li>Ajudam a centralizar informações importantes.</li>
      </ul>

      <v-alert
        icon="mdi-creation"
        type="info"
        class="mb-0 mt-4"
        text
        dense
        @click="newSmartFAQ"
        style="cursor: pointer"
      >
        DICA: Use o <b>Smart FAQ</b> para criar perguntas frequentes
        automaticamente com base nas dúvidas mais comuns dos clientes. Clique
        aqui para começar.
      </v-alert>
    </v-alert>
    <smart-faq
      :party="party"
      @refresh="getFAQ"
      :faqs="faqs"
      ref="smartFaqModal"
    />
    <faq-modal :party="party" @success="getFAQ" ref="faqModal" />
  </div>
</template>

<script>
import FAQ from "@/services/admin/faq";
import { mapGetters } from "vuex";
import FaqModal from "./modal/FaqModal.vue";
import SmartFaq from "./modal/SmartFaqModal.vue";
import FaqViewer from "./FAQViewer.vue";

export default {
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
  components: { FaqModal, FaqViewer, SmartFaq },
  data: () => ({
    faqs: [],
    loading: false,
    error: null,
  }),
  methods: {
    newFAQ() {
      this.$emit("faq-modal");
    },
    newSmartFAQ() {
      this.$emit("smart-faq-modal");
    },
    async getFAQ() {
      try {
        this.loading = true;
        const { faqs } = await FAQ.getAll(
          this.selectedOrganization.id,
          this.party.id
        );
        this.faqs = faqs;
      } catch (error) {
        this.error =
          error.message || "Erro ao carregar as perguntas frequentes";
      } finally {
        this.loading = false;
      }
    },
    openEditModal(faq) {
      this.$refs.faqModal.open(faq);
    },
    async deleteFAQ(id) {
      try {
        await FAQ.destroy(this.selectedOrganization.id, this.party.id, id);
        this.getFAQ();
      } catch (error) {
        this.error = error.message || "Erro ao deletar a pergunta frequente";
      }
    },
  },
  mounted() {
    this.getFAQ();
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
  },
};
</script>

<style></style>
