<template>
  <div class="pt-1">
    <v-text-field
      v-model="maxUses"
      label="Máximo de usos por cupom"
      hint="Deixe em branco para uso ilimitado"
      placeholder="Ilimitado"
      outlined
      type="number"
    ></v-text-field>

    <v-textarea
      v-model="codes"
      label="Códigos"
      hint="Um código por linha, mínimo de 4 caracteres"
      persistent-hint
      outlined
      :rules="[(v) => !!v || 'Campo obrigatório']"
    >
    </v-textarea>
    <v-alert type="info" text dense>
      {{ validCodes.length }} códigos válidos
    </v-alert>
    <v-alert type="warning" text dense>
      <strong>Atenção:</strong> Códigos duplicados serão removidos.
    </v-alert>
  </div>
</template>

<script>
export default {
  data: () => ({
    maxUses: 1,
    codes: "",
    validCodes: [],
  }),

  watch: {
    codes(val) {
      const codes = new Set(
        val.split("\n").map((code) => code.trim().toUpperCase())
      );
      const uniqueCodes = Array.from(codes).join("\n");

      this.codes = uniqueCodes.toUpperCase();
      this.validCodes = uniqueCodes
        .split("\n")
        .filter((code) => code.length >= 4)
        .map((code) => ({ code, maxUses: this.maxUses }));

      this.$emit("input", {
        ...this.value,
        codes: this.validCodes,
      });
      this.$emit("update:valid", this.validCodes.length > 0);
    },
  },
  props: {
    value: {
      type: Object,
      default: () => defaultData(),
    },
    party: {
      type: Object,
      default: () => ({}),
    },
    ticketGroups: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style>
</style>