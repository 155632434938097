<template>
  <v-card outlined class="pa-4 mt-6">
    <p class="font-weight-bold mb-1">Modo técnico</p>
    <div class="d-flex gap-2">
      <v-btn
        color="secondary"
        @click="copyTicketGroups"
        :disabled="!!loading"
        small
        depressed
      >
        Copiar todos setores
      </v-btn>
      <v-btn
        color="secondary"
        @click="pasteTicketGroups"
        :loading="loading === 'pasteTicketGroups'"
        :disabled="!!loading"
        small
        depressed
      >
        Colar setores
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import TICKET from "@/services/admin/ticket";

export default {
  props: {
    party: {
      type: Object,
      required: true,
    },
    ticketGroups: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    writeToClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => window.alert("Copiado com sucesso"),
        () => window.alert("Erro ao copiar")
      );
    },
    async copyTicketGroups() {
      const ticketGroups = this.ticketGroups.map((tg) => {
        return {
          ...tg,
          type: "ticketGroup",
          TicketBlock: tg.TicketBlock.map((tb) => ({
            ...tb,
            type: "ticketBlock",
            id: undefined,
          })),
          id: undefined,
        };
      });

      this.writeToClipboard(JSON.stringify(ticketGroups));
    },
    async pasteTicketGroups() {
      try {
        this.loading = "pasteTicketGroups";
        const text = await navigator.clipboard.readText();
        const ticketGroups = JSON.parse(text);

        if (!Array.isArray(ticketGroups))
          return window.alert("Conteúdo inválido");
        if (ticketGroups.some((tg) => tg.type !== "ticketGroup"))
          return window.alert("Conteúdo inválido");

        for (const tg of ticketGroups) {
          const { ticketGroup } = await TICKET.createTicketGroup(
            this.party.organizationId,
            this.party.id,
            { ...tg, TicketBlock: undefined }
          );

          for (const tb of tg.TicketBlock) {
            await TICKET.createTicketBlock(
              this.party.organizationId,
              this.party.id,

              { ...tb, ticketGroup: ticketGroup.id }
            );
          }
        }

        window.alert("Setores colados com sucesso");
        this.$emit("refresh");
      } catch (error) {
        console.error(error);
        window.alert("Erro ao colar setores");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style>
</style>