<template>
  <div>
    <v-progress-linear v-if="loading" indeterminate />

    <v-card outlined class="pa-4 pb-1 rounded-lg mt-2">
      <div class="d-flex align-center justify-space-between flex-wrap">
        <h6 class="mb-0">Solicitações de Reembolsos</h6>
        <v-btn-toggle mandatory v-model="refoundRequestFilterType">
          <v-btn small value="pending"> Pendentes </v-btn>
          <v-btn small value="finished"> Finalizadas </v-btn>
        </v-btn-toggle>
      </div>
      <v-data-table
        :expanded.sync="expanded"
        :headers="refundRequestsHeaders"
        :items="refundRequestsGrouped[refoundRequestFilterType]"
      >
        <template v-slot:item.createdAt="{ item }">
          {{ item.createdAt | date("DD/MM/YYYY HH:mm") }}
        </template>
        <template v-slot:item.ticketGroup="{ item }">
          {{ item.Ticket.TicketBlock.TicketGroup.name }} /
          {{ item.Ticket.TicketBlock.name }}
        </template>
        <template v-slot:item.amount="{ item }">
          {{ item.Ticket.Payment.amount | currency(true) }}
        </template>
        <template v-slot:item.data-table-expand="{ item }">
          <v-icon small @click="openRefundDetails(item)">mdi-information</v-icon>
        </template>
      </v-data-table>
    </v-card>
    <v-card outlined class="pa-4 pb-1 rounded-lg mt-2">
      <div class="d-flex align-center justify-space-between flex-wrap">
        <h6 class="mb-0">Ingressos Reembolsos</h6>
        <span>
          {{ refundedTickets.length }} reembolso{{
            refundedTickets.length == 1 ? "" : "s"
          }}
        </span>
      </div>

      <v-data-table :headers="ticketsHeaders" :items="refundedTickets">
        <template v-slot:item.refundedAt="{ item }">
          {{ item.Payment.refundedAt | date("DD/MM/YYYY HH:mm") }}
        </template>
        <template v-slot:item.ticketGroup="{ item }">
          {{ item.TicketBlock.TicketGroup.name }} /
          {{ item.TicketBlock.name }}
        </template>
        <template v-slot:item.refoundAmount="{ item }">
          {{ item.Payment.refundedAmount | currency(true) }}
          ({{
            (
              (item.Payment.amount
                ? item.Payment.refundedAmount / item.Payment.amount
                : 1) * 100
            ).toFixed(0)
          }}%)
        </template>
      </v-data-table>
    </v-card>
    <ticket-refund-details 
      :item="selectedRefundItem"
      @close="showRefundDetailsDialog = false"
      @refresh="getRefunds"
    />
  </div>
</template>

<script>
import PARTY from "@/services/admin/party";
import TicketRefundDetails from "./TicketRefundRow.vue";

export default {
  components: { TicketRefundDetails },
  data: () => ({
    loading: true,
    expanded: [],
    refoundRequestFilterType: "pending", // pending | finished
    refundRequests: [],
    refundedTickets: [],
    selectedRefundItem: null,
    showRefundDetailsDialog: false,
    ticketsHeaders: [
      { text: "Nome", value: "Owner.name" },
      { text: "Reembolsado em", value: "refundedAt" },
      { text: "Setor / Lote", value: "ticketGroup" },
      { text: "Valor reembolsado", value: "refoundAmount" },
      // { text: "Opções", value: "option", sortable: false },
    ],
  }),
  methods: {
    async getRefunds() {
      try {
        this.loading = true;
        const { refundRequests } = await PARTY.refund.getAllRequests(
          this.party.organizationId,
          this.party.id
        );
        this.refundRequests = refundRequests;

        const { tickets } = await PARTY.refund.getAll(
          this.party.organizationId,
          this.party.id
        );

        this.refundedTickets = tickets;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    openRefundDetails(item) {
      this.selectedRefundItem = item;
      this.showRefundDetailsDialog = true;
      this.$root.$emit("refund-details", item);
    },
  },
  mounted() {
    this.getRefunds();
    this.$root.$on("refund-ticket:success", this.getRefunds);
  },
  destroyed() {
    this.$root.$off("refund-ticket:success", this.getRefunds);
  },
  computed: {
    refundRequestsGrouped() {
      return this.refundRequests.reduce(
        (acc, curr) => {
          if (curr.closedAt) acc.finished.push(curr);
          else acc.pending.push(curr);

          return acc;
        },
        {
          finished: [],
          pending: [],
        }
      );
    },
    refundRequestsHeaders() {
      const data = [
        { text: "Nome", value: "Ticket.Owner.name" },
        { text: "Solicitado em", value: "createdAt" },
        { text: "Setor / Lote", value: "ticketGroup" },
        { text: "Valor", value: "amount" },
        { text: "", value: "data-table-expand" },
        // { text: "Opções", value: "option", sortable: false },
      ];
      return data;
    },
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
