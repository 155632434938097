<template>
  <v-card outlined :loading="loading" v-intersect="onIntersect">
    <v-card-title class="justify-space-between">
      <h6 class="mb-0 lh-1">Cortesias</h6>
      <h5 class="mb-0 lh-1">
        {{ courtesyTickets.length }} ingresso{{
          courtesyTickets.length == 1 ? "" : "s"
        }}
      </h5>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :items="sellersList"
        :headers="headers"
        hide-default-footer
        single-expand
        item-key="seller.id"
        show-expand
      >
        <template v-slot:item.count="{ item }">
          {{ item.tickets.length }} ingresso{{
            item.tickets.length == 1 ? "" : "s"
          }}
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="pa-2">
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Contemplado</th>
                    <th class="text-right">Lote</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="ticket in item.tickets" :key="ticket.id">
                    <td>
                      <b class="d-block">
                        {{ ticket.Owner.name || "Não cadastrado" }}
                      </b>
                      {{ ticket.Owner.phone }}
                    </td>
                    <td class="text-right">
                      {{ ticket.TicketBlock.TicketGroup.name }} -
                      {{ ticket.TicketBlock.name }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    expanded: null,
    isIntersecting: false,
    viewed: false,
    headers: [
      {
        text: "Vendedor",
        value: "seller.name",
        align: "left",
      },
      {
        text: "Ingressos",
        value: "count",
        align: "left",
      },
      { text: "", value: "data-table-expand" },
    ],
  }),
  methods: {
    onIntersect(entries) {
      if (entries[0].isIntersecting) {
        this.isIntersecting = true;
        this.viewed = true;
      } else {
        this.isIntersecting = false;
      }
    },
  },
  computed: {
    courtesyTickets() {
      if (!this.viewed) return [];
      return this.data.tickets.filter(
        (ticket) =>
          ticket.Payment?.status == "succeeded" &&
          ticket.Payment?.paymentType == "COURTESY"
      );
    },
    sellersList() {
      return Object.values(
        this.courtesyTickets.reduce((acc, ticket) => {
          var id = ticket.Seller?.id || ticket.SaleLink?.id;
          if (!id) return acc;

          if (!acc[id])
            acc[id] = {
              seller: ticket.Seller || ticket.SaleLink,
              tickets: [],
            };

          acc[id].tickets.push({
            ...ticket,
            Seller: undefined,
            SaleLink: undefined,
          });
          return acc;
        }, {})
      ).map((seller) => ({
        ...seller,
        count: seller.tickets.length,
      }));
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style></style>
