<template>
  <v-dialog
    v-model="isOpen"
    width="700"
    persistent
    scrollable
    :fullscreen="$vuetify.breakpoint.smAndDown"
    content-class="rounded-lg"
  >
    <v-card class="h-full d-flex flex-column" style="max-height: 90dvh">
      <v-card-title class="pb-0 pt-3 px-0 flex-column">
        <div class="d-flex w-full justify-space-between px-6">
          <span class="text-16">
            {{ ticketBlock?.id ? "Editar lote" : "Adicionar lote" }}
          </span>
          <v-chip label small>
            {{
              this.ticketGroups.find(
                (g) =>
                  g.id ==
                  (ticketBlock?.ticketGroup || ticketBlock?.ticketGroupId)
              )?.name
            }}
          </v-chip>
        </div>
        <div class="w-full">
          <v-stepper
            v-model="step"
            class="elevation-0 text-14 font-weight-regular"
            :non-linear="!!ticketBlock?.id"
          >
            <v-stepper-header class="elevation-0 mb-3" style="height: 38px">
              <template v-for="(stp, i) in steps">
                <v-divider v-if="i" :key="`divider-` + i" />
                <v-stepper-step
                  :key="`step-` + i"
                  :complete="step > i + 1"
                  :step="i + 1"
                  :editable="
                    !!ticketBlock?.id && !(!validInfo || !validMembership)
                  "
                  class="py-0"
                >
                  {{ stp }}
                </v-stepper-step>
              </template>
            </v-stepper-header>
          </v-stepper>
        </div>
      </v-card-title>

      <v-card-text class="pa-0 ma-0">
        <v-stepper
          v-model="step"
          class="elevation-0"
          :non-linear="!!ticketBlock?.id"
        >
          <v-stepper-items>
            <v-stepper-content step="1" class="pt-0">
              <ticket-block-info
                v-model="ticketBlock"
                @valid="validInfo = $event"
                :party="party"
                :ticketGroups="ticketGroups"
                :lastTicketBlock="lastTicketBlock"
              />
            </v-stepper-content>
            <v-stepper-content v-if="steps.length == 3" step="2" class="pt-0">
              <ticket-block-membership
                v-model="ticketBlock.TicketBlockMembership"
                :ticket-block="ticketBlock"
                @valid="validMembership = $event"
              />
            </v-stepper-content>
            <v-stepper-content :step="steps.length" class="pt-0">
              <ticket-block-sellers
                v-model="sellers"
                :members="members"
                :ticket-block="ticketBlock"
                :posSessions="posSessions"
              />
              <v-alert v-if="!!error" outlined text color="error" class="mt-2">
                {{ error }}
              </v-alert>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>

      <v-card-actions class="d-flex align-end gap-1">
        <template v-if="step === 1">
          <v-switch
            class="mt-0"
            v-model="ticketBlock.active"
            label="Ativo"
            :messages="[ticketBlock.active ? 'Lote ativo' : 'Lote inativo']"
          />
          <v-spacer />
          <v-btn text :disabled="loading" @click="close"> Cancelar </v-btn>
          <v-btn
            v-if="ticketBlock.id && config.opMode"
            color="success"
            :disabled="!validInfo"
            :loading="loading"
            @click="save"
            icon
          >
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
          <v-btn
            color="primary"
            :disabled="!validInfo || loading"
            @click="step++"
          >
            Continuar
          </v-btn>
        </template>
        <template v-if="steps[step - 1] == 'Membros'">
          <v-spacer />
          <v-btn class="ml-1" text :disabled="loading" @click="step--">
            Voltar
          </v-btn>
          <v-btn
            v-if="ticketBlock.id && config.opMode"
            color="success"
            :disabled="!validMembership"
            :loading="loading"
            @click="save"
            icon
          >
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
          <v-btn
            color="primary"
            :disabled="!validMembership || loading"
            @click="step++"
            class="ml-2"
          >
            Continuar
          </v-btn>
        </template>
        <template v-if="step === steps.length">
          <p class="align-self-center mb-0">
            {{ sellers.length }} vendedor{{
              sellers.length != 1 ? "es" : ""
            }}
            selecionado{{ sellers.length != 1 ? "s" : "" }}
          </p>
          <v-spacer />
          <v-btn class="ml-1" text @click="step--" :disabled="loading">
            Voltar
          </v-btn>
          <v-btn
            color="primary"
            :disabled="loading || !validInfo"
            :loading="loading"
            @click="save"
          >
            {{ ticketBlock?.id ? "Salvar" : "Criar" }}
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import ORGANIZATION from "@/services/admin/organization";
import TICKET from "@/services/admin/ticket";
import moment from "moment";
import TicketBlockInfo from "./modules/TicketBlockInfo.vue";
import TicketBlockSellers from "./modules/TicketBlockSellers.vue";
import TicketBlockMembership from "./modules/TicketBlockMembership.vue";

const defaultTicketBlock = () => ({
  id: null,
  name: "",
  price: null,
  quantity: null,
  startDate: moment().format("YYYY-MM-DDTHH:mm"),
  endDate: moment().format("YYYY-MM-DDTHH:mm"),
  active: true,
  onlineSale: false,
  allowTransfer: false,
  ticketGroup: null,
  TicketBlockMembership: [],
});

export default {
  components: { TicketBlockInfo, TicketBlockSellers, TicketBlockMembership },
  data: () => ({
    isOpen: false,
    loading: false,
    validInfo: false,
    validMembership: false,
    error: false,
    step: 1,
    ticketBlock: defaultTicketBlock(),
    members: [],
    sellers: [],
  }),

  methods: {
    open(data = {}) {
      this.isOpen = true;
      data = { ...defaultTicketBlock(), ...data };
      // sellers
      const sellers = [];
      if (data.Sellers) {
        sellers.push(
          ...this.members
            .filter((member) => data.Sellers.some((s) => s.id == member.id))
            .map((s) => ({ ...s, type: "user" }))
        );
      }
      if (data.PosSellers) {
        sellers.push(
          ...this.posSessions
            .filter((session) =>
              data.PosSellers.some((s) => s.id == session.id)
            )
            .map((s) => ({ ...s, type: "pos" }))
        );
      }

      this.sellers = sellers;

      // Format Date
      data.startDate = data.startDate
        ? moment(data.startDate).format("YYYY-MM-DDTHH:mm")
        : data.startDate;
      data.startType = !!data.startDate ? true : false;
      data.endDate = data.endDate
        ? moment(data.endDate).format("YYYY-MM-DDTHH:mm")
        : data.endDate;

      // Assign data
      this.ticketBlock = Object.assign(defaultTicketBlock(), data);
    },
    close() {
      this.isOpen = false;
      this.loading = false;
      this.error = false;
      this.step = 1;
      this.sellers = [];
      this.ticketBlock = defaultTicketBlock();
    },
    async save() {
      try {
        this.loading = true;
        this.error = false;
        let orgId = this.selectedOrganization.id;
        let { partyId } = this.$route.params;

        var startDate = moment(this.ticketBlock.startDate);
        if (!this.ticketBlock.startType) startDate = null;

        const sellersGrouped = this.sellers.reduce(
          (acc, s) => {
            if (!acc[s.type]) acc[s.type] = [];
            acc[s.type].push(s.id);
            return acc;
          },
          { user: [], pos: [] }
        );

        if (this.ticketBlock.id) {
          await TICKET.updateTicketBlock(orgId, partyId, {
            id: this.ticketBlock.id,
            ticketGroup: this.ticketBlock.ticketGroup,
            name: this.ticketBlock.name,
            price: this.ticketBlock.price,
            quantity: parseInt(this.ticketBlock.quantity),
            startDate: startDate?.toISOString(),
            endDate: moment(this.ticketBlock.endDate).toISOString(),
            allowTransfer: this.ticketBlock.allowTransfer,
            active: this.ticketBlock.active,
            onlineSale: this.ticketBlock.onlineSale,
            sellers: sellersGrouped.user,
            posSellers: sellersGrouped.pos,
            TicketBlockMembership: this.ticketBlock.TicketBlockMembership,
          });
        } else {
          await TICKET.createTicketBlock(orgId, partyId, {
            ticketGroup: this.ticketBlock.ticketGroup,
            name: this.ticketBlock.name,
            price: this.ticketBlock.price,
            quantity: parseInt(this.ticketBlock.quantity),
            startDate: startDate?.toISOString(),
            endDate: moment(this.ticketBlock.endDate).toISOString(),
            allowTransfer: this.ticketBlock.allowTransfer,
            active: this.ticketBlock.active,
            onlineSale: this.ticketBlock.onlineSale,
            sellers: sellersGrouped.user,
            posSellers: sellersGrouped.pos,
            TicketBlockMembership: this.ticketBlock.TicketBlockMembership,
          });
        }
        this.$emit("success");
        this.close();
      } catch (e) {
        this.error = e.message;
        this.loading = false;
      }
    },
    async getMembers() {
      try {
        this.loading = true;
        var response = await ORGANIZATION.members(this.selectedOrganization.id);
        this.members = response.users.filter((u) => {
          return (
            u.owner ||
            (32 & u.permission) === 32 ||
            (1024 & u.permission) === 1024
          );
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    formatName(name) {
      return name
        .split(" ")
        .map((n) => (n.length > 3 ? n.charAt(0).toUpperCase() + n.slice(1) : n))
        .join(" ");
    },
  },
  watch: {
    "ticketBlock.name": {
      handler: function (val) {
        this.ticketBlock.name = this.formatName(val);
      },
      immediate: true,
    },
  },

  computed: {
    ...mapGetters("auth", ["config"]),
    ...mapGetters("organization", ["memberships", "selectedOrganization"]),
    steps() {
      const data = ["Informações"];
      if (this.memberships.some((m) => m.active)) data.push("Membros");
      data.push("Vendedores");
      return data;
    },
    lastTicketBlock() {
      if (!this.ticketBlock) return null;
      const group = this.ticketGroups.find(
        (g) =>
          g.id ==
          (this.ticketBlock?.ticketGroup || this.ticketBlock?.ticketGroupId)
      );
      if (!group) return null;

      const ticketBlocks = group.TicketBlock.filter(
        (t) =>
          t.active &&
          this.ticketBlock.id != t.id &&
          t.quantity > 0 &&
          t.onlineSale === this.ticketBlock.onlineSale &&
          new Date(t.endDate) > new Date() &&
          parseInt(t.price) <= parseInt(this.ticketBlock.price)
      );
      if (ticketBlocks.length == 0) return null;
      return ticketBlocks.sort((a, b) => b.price - a.price)[0];
    },
  },
  mounted() {
    this.$root.$on("ticket-block-modal", this.open);
    this.getMembers();
  },
  props: {
    party: {
      type: Object,
      default: () => ({}),
    },
    ticketGroups: {
      type: Array,
      default: () => [],
    },
    posSessions: {
      type: Array,
      default: () => [],
    },
  },
  created() {},
};
</script>

<style></>
