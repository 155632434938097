<template>
  <div>
    <v-card-text>
      <template v-if="!complete">
        <v-alert v-if="!ticketGroups.length" class="mb-0" type="warning" text>
          Você não possui permissão para emitir nenhum lote como cortesia.
        </v-alert>
        <template v-else>
          <h5>Autorizar responsável</h5>

          <v-alert type="info" dense text>
            O responsável receberá um link para emitir cortesias que serão
            vinculadas a ele.
          </v-alert>
          <phone-input
            :phone="user.phone"
            @update:phone="(e) => (user.phone = e)"
            :ddi="user.ddi"
            @update:ddi="(e) => (user.ddi = e)"
            @valid="(v) => (validPhone = v)"
            dense
            outlined
            label="WhatsApp do Responsável"
            :disabled="loading"
          />

          <div>
            <div
              v-for="(tbItem, i) in ticketBlocks"
              :key="i"
              class="d-flex gap-2"
            >
              <v-autocomplete
                v-model="ticketBlocks[i].ticketBlock"
                :items="[...availableTicketBlocks, ticketBlocks[i].ticketBlock]"
                item-text="text"
                return-object
                label="Lote"
                dense
                outlined
                :disabled="loading"
                clearable
                style="max-width: calc(100% - 98px)"
                :prepend-icon="i ? 'mdi-close' : null"
                @click:prepend="ticketBlocks.splice(i, 1)"
              ></v-autocomplete>
              <v-text-field
                v-model="ticketBlocks[i].quantity"
                label="Quantidade"
                dense
                outlined
                type="number"
                style="max-width: 90px"
                :disabled="loading"
                min="1"
                :rules="[
                  (v) => !!v || 'Campo obrigatório',
                  (v) => v > 0 || 'Quantidade inválida',
                ]"
              ></v-text-field>
            </div>
            <v-btn
              text
              block
              @click="ticketBlocks.push({ ticketBlock: null, quantity: 1 })"
              :disabled="
                loading || ticketBlocks.length >= ticketBlocksList.length
              "
            >
              Adicionar lote
            </v-btn>
          </div>

          <v-alert v-if="error" class="mb-0 mt-4" type="error" text>
            {{ error }}
          </v-alert>
        </template>
      </template>
      <template v-else>
        <h5 class="mb-2">
          {{ loading ? "Enviando cortesias" : "Cortesias enviadas" }}
        </h5>

        <v-card outlined class="d-flex align-center gap-2 pa-3">
          <v-avatar v-if="!success" size="40">
            <v-icon large>mdi-account</v-icon>
          </v-avatar>
          <base-avatar v-else :seed="user.id" :src="user.photo" :size="40" />
          <div>
            <h6 class="mb-0 lh-1">{{ user.name || user.phone }}</h6>
            <small v-if="user.name" class="mb-0 lh-1">{{ user.phone }}</small>
          </div>
        </v-card>
        <v-alert
          v-if="success && !user.name"
          class="mt-4 mb-0"
          type="info"
          dense
          text
        >
          O responsável ainda não possui cadastro. Será necessário criar uma
          conta para acessar as cortesias.
        </v-alert>

        <v-list>
          <v-list-item v-for="(tb, i) in ticketBlocks" :key="i">
            <v-list-item-content>
              <v-list-item-title>
                {{ tb.ticketBlock.text }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ tb.quantity }} cortesia{{ tb.quantity > 1 ? "s" : "" }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-btn block text :disabled="loading" @click="$emit('close', true)">
          Fechar
        </v-btn>
      </template>
    </v-card-text>
    <v-card-actions v-if="!complete" class="justify-space-between">
      <v-btn text @click="$emit('prev')"> Voltar </v-btn>
      <v-btn
        color="primary"
        @click="send"
        :disabled="
          loading ||
          !validPhone ||
          !ticketBlocks.every((tb) => tb.ticketBlock && tb.quantity > 0)
        "
      >
        Continuar
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import COURTESY_EMITTER from "@/services/admin/courtesyEmitter";
import PhoneInput from "../../../global/PhoneInput.vue";

export default {
  components: { PhoneInput },
  props: {
    party: {
      type: Object,
      required: true,
    },
    ticketGroups: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      complete: false,
      error: null,
      success: false,

      user: {
        phone: "",
        ddi: 55,
      },
      ticketBlocks: [
        {
          ticketBlock: null,
          quantity: 1,
        },
      ],
      validPhone: false,
    };
  },
  methods: {
    async send() {
      try {
        this.loading = true;
        this.error = null;
        this.complete = true;
        this.$emit("next");

        const data = {
          user: this.user,
          ticketBlocks: this.ticketBlocks.map(({ ticketBlock, quantity }) => ({
            ticketBlockId: ticketBlock.id,
            quantity,
          })),
        };

        const { user } = await COURTESY_EMITTER.create(
          this.party.organizationId,
          this.party.id,
          data
        );
        this.user = user;

        this.success = true;
      } catch (error) {
        this.error = error.message || "Erro ao emitir cortesias";
        this.complete = false;
        this.$emit("prev");
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {},
  computed: {
    ticketBlocksList() {
      const ticketBlocks = [];
      this.ticketGroups
        .filter(({ tableGroupId }) => !tableGroupId)
        .forEach((ticketGroup) => {
          ticketGroup.TicketBlock.forEach((ticketBlock) => {
            ticketBlocks.push({
              text: `${ticketGroup.name} - ${ticketBlock.name}`,
              id: ticketBlock.id,
            });
          });
        });

      return ticketBlocks;
    },
    availableTicketBlocks() {
      const selectedIds = this.selectedTicketBlocks;
      return this.ticketBlocksList.filter(
        (block) => !selectedIds.includes(block.id)
      );
    },
    selectedTicketBlocks() {
      return this.ticketBlocks.map((tb) => tb.ticketBlock?.id);
    },
  },
};
</script>

<style></style>
