<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="800px"
    :persistent="step > 1"
  >
    <v-stepper v-model="step">
      <v-card>
        <v-card-title class="d-block pa-0">
          <v-stepper-header>
            <v-stepper-step :complete="step > 1" step="1"> </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="step > 2" step="2"> </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="3"> </v-stepper-step>
          </v-stepper-header>
        </v-card-title>
        <v-stepper-items>
          <v-stepper-content step="1" class="pa-1">
            <v-card-text>
              <h5>Como deseja emitir a cortesia?</h5>
              <v-row class="mx-0" dense align="stretch">
                <v-col
                  v-for="(method, index) in methods"
                  :key="index"
                  cols="12"
                  sm="6"
                >
                  <v-card
                    @click="selectedMethod = method"
                    class="h-full cursor-pointer pa-4 d-flex flex-column"
                    outlined
                  >
                    <div class="d-flex align-center justify-space-between mb-2">
                      <h6 class="mb-0">{{ method.title }}</h6>
                      <v-icon>
                        mdi-radiobox-{{
                          selectedMethod === method ? "marked" : "blank"
                        }}
                      </v-icon>
                    </div>
                    <div
                      class="flex-grow-1 flex-shrink-0 d-flex flex-column justify-center"
                    >
                      <p class="mb-0">{{ method.description }}</p>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="justify-space-between">
              <v-btn text @click="close"> Cancelar </v-btn>
              <v-btn
                color="primary"
                :disabled="!selectedMethod || !ticketGroups"
                :loading="!ticketGroups"
                @click="step = 2"
              >
                Continue
              </v-btn>
            </v-card-actions>
          </v-stepper-content>

          <v-scroll-x-reverse-transition leave-absolute mode="out-in">
            <div v-if="step > 1" class="pa-1">
              <component
                v-if="selectedMethod"
                :is="selectedMethod.component"
                :party="party"
                :ticketGroups="ticketGroups"
                @next="step++"
                @prev="step--"
                @close="close"
              ></component>
            </div>
          </v-scroll-x-reverse-transition>
        </v-stepper-items>
      </v-card>
    </v-stepper>
  </v-dialog>
</template>                        

<script>
import TICKET from "@/services/admin/ticket";
import CourtesyPersonal from "./CourtesyPersonal.vue";
import CourtesyResponsible from "./CourtesyResponsible.vue";

export default {
  components: { CourtesyPersonal, CourtesyResponsible },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      step: 1,
      selectedMethod: null,
      ticketGroups: null,
      methods: [
        {
          title: "Personalíssima",
          description:
            "Envie uma cortesia diretamente para uma pessoa específica.",
          component: "courtesy-personal",
        },
        {
          title: "Por responsável",
          description:
            "Defina um responsável para emitir cortesias através de um link.",
          component: "courtesy-responsible",
        },
        // {
        //   title: "Impressa",
        //   description:
        //     "Gere cortesias impressas sem precisar identificar o contemplado.",
        // },
      ],
    };
  },
  methods: {
    open() {
      this.step = 1;
      this.selectedMethod = null;
      this.dialog = true;
      this.getTickets();
    },
    close(refresh = false) {
      this.dialog = false;
      if (refresh) this.$emit("refresh");
    },
    async getTickets() {
      try {
        this.ticketGroups = [];

        const response = await TICKET.canSell(
          this.party.organizationId,
          this.party.id
        );
        this.ticketGroups = response.ticketGroups;
      } catch (error) {
        console.error(error);
        this.error = error.message;
      }
    },
  },

  mounted() {
    this.$parent.$on("openEmitterModal", this.open);
  },
};
</script>

<style>
</style>